@font-face {
  font-family: "bold";
  src: url("../Font/EniExpBol.otf");
}
@font-face {
  font-family: "light";
  src: url("../Font/EniExpLig.otf");
}
@font-face {
  font-family: "regular";
  src: url("../Font/EniExpReg.otf");
}

body {
  font-family: "regular";
  height:100%
}



div.propertyItemText {
  font-size: 1.1em !important;
}

.filterMenu-container {
  background-color: #848484;
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 100;
  border-radius: 8px;
}

div.appContainer {
  background-color: #fff;
}

div.notification {
  /* width: 97%; */
  border-radius: 10px;
  padding: 8px 20px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
}

div.notification.error {
  background-color: rgba(255, 65, 65, 0.95);
  color: #fff;
}

div.notification.noNotify {
  background-color: rgba(65, 157, 255, 0.95);
  color: #fff;
}

div.notification.success {
  background-color: rgba(64, 144, 94, 0.95);
  color: #fff;
}

div.notification.warning {
  background-color: rgba(255, 206, 81, 0.95);
}

.relative {
  position: relative;
}

.primary-bg {
  background-color: #f3cc22;
}

.bold,
.bolder {
  font-family: "bold";
}

.reg {
  font-family: "regular";
}

.light {
  font-family: "light";
}

h1,
h2,
h3,
h4 {
  font-family: "bold";
}

input.inputSearch {
  height: 45px;
}

input.inputForm{
  height: 38px;
}

button.inputSearch {
  padding: 0 20px;
  color: #000;
}

button.btn-filters {
  padding: 5px 50px;
  height: 40px;
  border-radius: 20px;
  color: #000;
  background-color: #fff;
  border: 1px solid #888;
}

button.btn-link-icon {
  font-size: 1.7em;
  padding-left: 0;
}

button.btn-link-icon div {
  width: 40px;
  margin-right: 0.5em;
}
button.btn-link-icon > div > img {
  height: 30px;
}

.trasparent-bg {
  background-color: transparent !important;
}

.white-border {
  border: 1px solid #fff;
}
.dark-border {
  border: 1px solid #000;
}

.btn-icon {
  padding: 4px;
}
.btn-icon.icon-sm > img {
  height: 15px;
}
.btn-icon > img {
  height: 20px;
}
.btn-icon.icon-md > img {
  height: 25px;
}
.btn-icon.icon-lg > img {
  height: 30px;
}

.btn-lg {
  min-height: 40px;
  min-width: 70px;
  padding-left: 25px;
  padding-right: 25px;
}

.btn-xl {
  min-height: 50px;
  min-width: 140px;
  font-size: 1.1em;
  padding-left: 25px;
  padding-right: 25px;
}

.buttonAction.disabled {
  filter: saturate(0);
  pointer-events: none !important;
}

.buttonAction:not(.active) {
  filter: saturate(0);
}

div.fakeCheckbox {
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}

div.fakeCheckbox.invert {
  border: 1px solid #727272;
}

div.fakeCheckbox.checked {
  background-color: #f3cc22 !important;
}

label.fakeCheckbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

div.loaderContainer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
}

img.loaderContainer {
  width: 100px;
}

.getLoader.true {
  display: flex !important;
}

button.pagination {
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

img.pagination {
  height: 20px;
  opacity: 0.6;
}

.notDisplay {
  display: none !important;
}

div.pageContainer {
  padding-top: 80px;
  padding-bottom: 100px;
}

div.css-yk16xz-control,
div.css-5duvv3-control,
div.css-1fhf3k1-control {
  min-height: 40px;
  border-radius: 10px;
  padding-left: 10px;
  border: 1px solid #888;
  font-family: "VodafoneRg";
}

div.css-2b097c-container,
div.css-14jk2my-container {
  margin-top: 5px;
}

@media (min-width: 992px) {
  .modal-lg.dialogConfirm {
    max-width: 500px;
  }
}

span.optionText{
  color: #000 !important;
}

/* DARKMODE */

div.appContainer.dark {
  background-color: #211d1e;
}

div.pageContainer.dark h1,
div.pageContainer.dark h2,
div.pageContainer.dark h2,
div.pageContainer.dark h3,
div.pageContainer.dark h4,
div.pageContainer.dark h5,
div.pageContainer.dark h6,
div.pageContainer.dark label,
div.pageContainer.dark span {
  color: white;
}

span.optionText.dark{
  color: #fff !important;
}

div.pageContainer.dark label.status:not(.Rejected) {
  color: #ffd400;
}

img.invert {
  filter: invert(100%);
}
img.brightness {
  filter: brightness(2) ;
}

.bright {
  filter: invert(1) brightness(2);
}

div.headerMobileContainer.dark {
  background-color: #413f40;
}

div.headerMobileContainer.dark h5 {
  color: white !important;
  letter-spacing: 0.5px;
}

.filterMenu-container.dark {
  background-color: #494748;
}

div.itr-item.dark {
  background-color: #413f40;
}

div.itr-item.hover.dark{
  border:1px solid rgb(0, 125, 228);
  background-color: #4d4b4c;
}

div#root.darkTheme{
  background-color: #211d1e;
  min-height: 100vh;
  overflow-y: hidden;
}

input.inputSearch.dark,
input.inputForm.dark{
  border: 1px solid #616161;
  background-color: #3B3B3B;
  color:white
}

input.inputSearch.dark::placeholder{
  color: #a0a0a0;
}