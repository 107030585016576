.addButton {
    width: 60px;
    border-radius: 50%;
    right: 25px;
    bottom: 125px;
    position: fixed;
    height: 60px;
}

@media only screen and (min-width: 1500px) {
    .addButton {
        width: 50px;
        border-radius: 50%;
        right: 120px;
        bottom: 60px;
        position: fixed;
        height: 50px;
    }
}

div.punchlist-item {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px 0;
    margin-bottom: 1rem;

    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
}