a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}

.headerMobileContainer {
  height: 60px;
  background-color: #fff;
  position: fixed;
  z-index: 100;
  top:0;
  left: 0;;
}

.trapezoidHeader {
  border-bottom: 60px solid #f3cc22;
  border-left: 50px solid transparent;
  position: absolute;
  right: 0;
  top: 0;
}

.logStatusIcon {
  height: 15px;
  width: 15px;
  border-radius: 10px;
}

.logStatusIcon.online {
  background-color: darkgreen;
}
.logStatusIcon.offline {
  background-color: red;
}

.logStatusText {
  font-weight: normal;
}

.footerNavMobile-container {
  height: 80px;
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

a.navIcon-container {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: bolder;
  font-size: 0.8em;
}
a.navIcon-container.horiz {
  text-decoration: none !important;
  color: #000 !important;
  font-weight: bolder;
  font-size: 1em;
}


a.navIcon-container.disabled {
  pointer-events: none !important;
  cursor: none;
  filter:invert(70%)

}

a.navIcon-container:not(.disabled) div img,
a.navIcon-container:not(.disabled) div label{
  cursor: pointer;
}

a.navIcon-container:not(.active) div img,
a.navIcon-container:not(.active) div label{
  filter:invert(30%)
}

a.navIcon-container:not(.disabled):hover {
  filter: brightness(0) !important;
}